import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {


    const footerLinks = [
        {
            href: "https://amusing-attic-3a5.notion.site/Enchant-0db60261f1f044be96b27cb8beaef124?pvs=4",
            label: 'About',
            external: true,
        },
        {
            href: "mailto:contact@ouranos-app.com",
            label: 'Contact Us',
            external: false,
        },
        {
            href: "https://ouranos-shop.myspreadshop.fr/",
            label: 'Shop',
            external: true,
        },
        {
            to: "/api",
            label: 'API',
            external: false,
        },
        {
            to: "/privacy",
            label: 'Privacy',
            external: false,
        },
        {
            to: "/terms",
            label: 'Terms',
            external: false,
        },
    ];

    return (
        <footer className="bg-gray-950 text-gray-400 py-6 border-t border-gray-800">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-2 gap-8 justify-items-center m-auto">
                    {/* Company Section */}
                    <div className="space-y-4 text-center">
                        <ul className="space-y-2">
                            {footerLinks
                                .filter(link => link.external)
                                .map((link, index) => (
                                    <li key={index}>
                                        <a
                                            href={link.href}
                                            target={link.external ? "_blank" : ""}
                                            rel="noopener noreferrer"
                                            className="hover:text-white"
                                        >
                                            {link.label}
                                        </a>
                                    </li>
                                ))}
                        </ul>
                    </div>

                    {/* Resources Section */}
                    <div className="space-y-4 text-center">
                        <ul className="space-y-2">
                            {footerLinks
                                .filter(link => !link.external)
                                .map((link, index) => (
                                    <li key={index}>
                                        {link.to ? (
                                            <Link to={link.to} className="hover:text-white">
                                                {link.label}
                                            </Link>
                                        ) : (
                                            <a
                                                href={link.href}
                                                className="hover:text-white"
                                            >
                                                {link.label}
                                            </a>
                                        )}
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>

                {/* Footer Bottom */}
                <div className="col-span-full mt-6 text-center">
                    <p className="text-sm">
                        © 2025 Paul Joly, all rights reserved
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
