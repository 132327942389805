import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Divider, ScrollShadow } from "@heroui/react";
import Image from '../assets/1.png';
import AppStore from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import PlayStore from '../assets/google-play-badge.png'
import { FaSun, FaCloud } from 'react-icons/fa';
import Footer from '../components/footer';
import tickImage from '../assets/tick.svg';
import Hourly from '../assets/hourly.png';
import Graph from '../assets/graph.png';
import { VscGraphLine } from "react-icons/vsc";
import QRcode from "../assets/qrcode.png"
import Logo from '../assets/logo.png'
import { IoSparkles } from "react-icons/io5";
import { IoTimeOutline } from "react-icons/io5";


const LandingPage = () => {




    return (
        <div >
            <Navbar isBordered isBlurred="false" className='bg-black font-medium' >
                <NavbarBrand className='flex  gap-2 ' >
                    <Link className='text-white' passHref color="foreground">
                        <img src={Logo} alt="Logo" className="w-14" />
                        <p>Ouranos</p>
                    </Link>

                </NavbarBrand>


                <NavbarContent justify="end" >
                    <NavbarItem>
                        <Link className='text-white' href="#features" passHref color="foreground">
                            Features
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link target="_blank" showAnchorIcon isExternal className='text-white' href='/api' color="foreground">
                            API
                        </Link>
                    </NavbarItem>
                </NavbarContent>
            </Navbar>



            <div className=' mx-auto my-6' >
                <div className="relative flex justify-center items-center md:hidden">
                    <img src={Image} alt="Logo" />
                </div>


                <div className=" flex items-center justify-center text-white  gap-12 px-4 ">
                    {/* Text Content */}
                    <div className="text-left space-y-6 max-w-2xl">
                        <h1 className="text-4xl md:text-5xl font-extrabold bg-gradient-to-r from-white to-stone-300 bg-clip-text text-transparent ">
                            Unlock the Skies with Ouranos
                        </h1>

                        <p className="text-lg text-gray-400">
                            Perfect nights for stargazing are rare, but with Ouranos, you'll never miss one again!
                        </p>


                        <div className="flex gap-4" >
                            <Link isExternal href="https://apps.apple.com/app/ouranos-compagnon-du-ciel/id6456041564" passHref>
                                <img src={AppStore} alt="Logo" className="w-40" />
                            </Link>

                            <Link isExternal href="https://play.google.com/store/apps/details?id=com.ouranos_app.www.twa" passHref>
                                <img src={PlayStore} alt="Logo" className="w-44" />
                            </Link>


                        </div>

                        <img src={QRcode} alt="Logo" className="w-28 rounded-md" />
                    </div>

                    {/* Image */}
                    <div className="relative flex justify-center items-center hidden md:block">
                        <img src={Image} alt="Logo" className="w-full max-w-xl" />
                    </div>
                </div>
            </div>




            <Divider className='bg-gray-900' />

            <div className="bg-dark text-white py-16 " id="features">
                <p className=" font-medium text-center mb-6 px-4 text-gray-400 text-xl">Features</p>

                <div className="flex flex-col justify-center items-center min-h-screen max-w-7xl mx-auto gap-16 px-6 md:px-12 lg:px-16">

                    {/* Astronomy Recommendations */}
                    <div className="flex flex-col md:flex-row items-center md:space-x-12 space-y-8 md:space-y-0">
                        <div className="bg-emerald-300 flex items-center space-x-3 p-4 rounded-lg shadow-lg">

                            <img src={tickImage} alt="Good for stargazing" />

                            <p className="m-0 text-emerald-950 font-semibold">The best observation window is between 0h and 5h.</p>
                        </div>
                        <div className="max-w-lg ">
                            <div className="flex items-center mb-4">
                                <IoSparkles className="text-indigo-300 text-2xl mr-3" />
                                <h3 className="text-xl font-semibold text-white">Astronomy Recommendations</h3>
                            </div>
                            <p className="text-gray-300">
                                Get AI-powered recommendations on the best times to observe or set up your equipment. Our API analyzes cloud cover, visibility, and other meteorological parameters to provide a textual recommendation and an indicator for optimal viewing conditions.
                            </p>
                        </div>
                    </div>



                    {/* Live Forecast Graph */}
                    <div className="flex flex-col md:flex-row items-center md:space-x-12 space-y-8 md:space-y-0 gap-4">
                        <div>
                            <img src={Graph} className="max-w-sm md:max-w-xs rounded-lg shadow-md" alt="Interactive Weather Graph" />
                        </div>
                        <div className="max-w-lg ">
                            <div className="flex items-center mb-4">
                                <VscGraphLine className="text-indigo-300 text-2xl mr-3" />
                                <h3 className="text-xl font-semibold text-white">Live Forecast Graph</h3>
                            </div>
                            <p className="text-gray-300">
                                Track key astronomical data with an interactive graph updated every 15 min. Stay ahead with precise forecasts for seeing, sky quality, and weather.
                            </p>
                        </div>
                    </div>

                    {/* Hourly Forecasts */}
                    <div className="flex flex-col-reverse md:flex-row items-center md:space-x-12 space-y-8 md:space-y-0 gap-4">
                        <div className="max-w-lg   ">
                            <div className="flex items-center mb-4">
                                <IoTimeOutline className="text-indigo-300 text-2xl mr-3" />
                                <h3 className="text-xl font-semibold text-white">Hourly Forecasts</h3>
                            </div>
                            <p className="text-gray-300">
                                Access detailed hourly forecasts designed for astronomers, including temperature, wind speed, and key astronomical data like seeing and sky quality indexes.
                            </p>
                        </div>

                        <div>
                            <img src={Hourly} className="max-w-sm md:max-w-xs rounded-lg shadow-md" alt="Hourly Forecasts" />
                        </div>

                    </div>
                </div>
            </div>


            <Divider className='bg-gray-900' />


            <div className="text-white py-12 px-6">
                <p className="font-medium text-center mb-6 px-4 text-gray-400 text-xl">What Our Users Say</p>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 max-w-6xl mx-auto">
                    {/* Review 1 */}
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
                        <div className="flex mb-2">
                            {'★★★★★'.split('').map((star, index) => (
                                <span key={index} className="text-yellow-400 text-lg">{star}</span>
                            ))}
                        </div>
                        <p className="text-lg">"A complete application for amateur astronomers, seeing, etc. You can even choose your weather model - a comparison would have been perfect."</p>
                        <div className="mt-4 flex items-center">
                            <img src="https://lh3.googleusercontent.com/a-/ALV-UjVj1P3QewL9S51OsJlFH0sNCKvWIk4IhE3UeqmuseqBqXgjy3Tr" alt="User 1" className="h-10 w-10 rounded-full mr-3" />
                            <span className="font-bold">Jean G.</span>
                        </div>
                    </div>

                    {/* Review 2 */}
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
                        <div className="flex mb-2">
                            {'★★★★★'.split('').map((star, index) => (
                                <span key={index} className="text-yellow-400 text-lg">{star}</span>
                            ))}
                        </div>
                        <p className="text-lg">"Very useful for organizing photo shoots."</p>
                        <div className="mt-4 flex items-center">
                            <img src="https://lh3.googleusercontent.com/a-/ALV-UjW9JrJzQdZD4J5QZj9co1oLmHxPGZovmEqp24ncscpMyCdpIXM8" alt="User 2" className="h-10 w-10 rounded-full mr-3" />
                            <span className="font-bold">Tulum Skywatcher</span>
                        </div>
                    </div>

                    {/* Review 3 */}
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
                        <div className="flex mb-2">
                            {'★★★★★'.split('').map((star, index) => (
                                <span key={index} className="text-yellow-400 text-lg">{star}</span>
                            ))}
                        </div>
                        <p className="text-lg">"Excellent is the word that describes this application. It surpasses them all."</p>
                        <div className="mt-4 flex items-center">
                            <img src="https://lh3.googleusercontent.com/a-/ALV-UjX8HMAneIYm3l_KdaJYFNq0j4FGkpvpFH0cNoFf9BXdo_zMnl2i" alt="User 3" className="h-10 w-10 rounded-full mr-3" />
                            <span className="font-bold">COSMOS PILOT.</span>
                        </div>
                    </div>

                    {/* Review 4 */}
                    <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
                        <div className="flex mb-2">
                            {'★★★★★'.split('').map((star, index) => (
                                <span key={index} className="text-yellow-400 text-lg">{star}</span>
                            ))}
                        </div>
                        <p className="text-lg">"Excellent astro app."</p>
                        <div className="mt-4 flex items-center">
                            <img src="https://lh3.googleusercontent.com/a-/ALV-UjUqHQLc8pvwWBNTVC_a5TOwXzhSIc7BdVsVOn_uZi_Y37AL569S" alt="User 4" className="h-10 w-10 rounded-full mr-3" />
                            <span className="font-bold">Christian S.</span>
                        </div>
                    </div>
                </div>
            </div>



            <Divider className='bg-gray-900' />

            <div className="container mx-auto px-4 py-16">
                <div className="text-center">
                    <h3 className="text-3xl font-bold mb-4 text-white">Try Ouranos for Free</h3>
                    <p className="text-gray-400 mb-6">Unlock powerful astronomy forecasts with no commitment.</p>

                    <div className="flex justify-center">
                        <div className="flex gap-4">
                            <Link isExternal href="https://apps.apple.com/app/ouranos-compagnon-du-ciel/id6456041564" passHref>
                                <img src={AppStore} alt="Download on the App Store" className="w-48" />
                            </Link>

                            <Link isExternal href="https://play.google.com/store/apps/details?id=com.ouranos_app.www.twa" passHref>
                                <img src={PlayStore} alt="Get it on Google Play" className="w-52" />
                            </Link>


                        </div>


                    </div>

                    <img src={QRcode} alt="Logo" className="m-auto w-32 rounded-md my-6" />




                </div>
            </div>


            <Footer />

        </div>
    );
};

export default LandingPage;
