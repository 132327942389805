import React, { useEffect } from 'react';
import { Spinner } from '@heroui/react';

const RedirectToStore = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.ouranos_app.www.twa';
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            window.location.href = 'https://apps.apple.com/app/id6456041564';
        } else {
            
            window.location.href = 'https://ouranos-app.com/';
        }
    }, []);

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black z-50">
            <Spinner color="white" size="lg" />
        </div>
    );
};

export default RedirectToStore;
